/** @jsx jsx */
import { Fragment } from 'react'
import { jsx } from 'theme-ui'
import Head from './Head'

const Main = () => {
  const handleButtonClick = () => {
    open(
      'https://timerex.net/s/info_f08c_3b71/2a13158e',
      '_blank',
      'noopener,noreferrer',
    )
  }
  return (
    <Fragment>
      <div
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100vh',
          color: 'white',
          fontFamily: 'Noto Sans JP, sans-serif',
        }}
      >
        <Head />
        <main
          sx={{
            position: 'relative',
            '@media screen and (max-width: 768px)': {
              width: '100vw',
              height: 'auto',
              boxSizing: 'border-box',
            },
          }}
        >
          <img
            src="my_pc.jpg"
            alt="my_pc.jpg"
            sx={{
              width: '100vw',
              height: 'calc(100vh - 74px)',
              objectFit: 'cover',
              objectPosition: [
                '20% center',
                '30% center',
                '30% center',
                '30% center',
                '40% center',
              ],
            }}
          />
          <div
            sx={{
              textAlign: 'center',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100vw',
            }}
          >
            <h1
              sx={{
                textAlign: 'left',
                fontSize: ['2rem', '2rem', '2rem', '2rem', '3rem'],
                minHeight: '175px',
                marginLeft: ['1rem', '1rem', '4rem'],
                marginBottom: '0',
              }}
            >
              ライフスタイルに
              <br />
              <div sx={{ marginLeft: ['3rem', '3rem', '4rem'] }}>
                合わせた働き方
              </div>
            </h1>
            <br />
            <p
              sx={{
                textAlign: 'left',
                display: 'flex',
                marginLeft: ['1rem', '1rem', '4rem'],
              }}
            >
              エンジビズなら
              <br />
              エンジニアのライフスタイルに
              <br />
              合わせたお仕事が見つかります。
            </p>
            <button
              onClick={handleButtonClick}
              sx={{
                backgroundColor: '#f04b4c;',
                color: 'white',
                border: 'none',
                padding: '1rem 4rem',
                fontSize: ['1rem', '1rem', '2rem'],
                fontWeight: 'bold',
                borderRadius: '8px',
                transform: 'skew(-20deg)',
                cursor: 'pointer',
                outline: 'none',
                marginLeft: ['1rem', '1rem', '4rem'],
                display: 'block',
              }}
            >
              今すぐ予約
            </button>
            <ul
              sx={{
                display: 'flex',
                listStyle: 'none',
                marginTop: [0, 0, 20],
              }}
            >
              <li
                sx={{
                  position: 'relative',
                  width: [110, 110, 250],
                  height: [110, 110, 250],
                }}
              >
                <img
                  src="star.png"
                  alt="平均年収"
                  sx={{
                    width: [110, 110, 250],
                    height: [110, 110, 250],
                  }}
                />
                <div
                  sx={{
                    position: 'absolute',
                    top: '47%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: ['0.5rem', '0.5rem', '1rem'],
                    fontWeight: 'bolder',
                  }}
                >
                  平均年収
                  <br />約<strong>940</strong>万
                </div>
              </li>
              <li
                sx={{
                  position: 'relative',
                  width: [110, 110, 250],
                  height: [110, 110, 250],
                }}
              >
                <img
                  src="star.png"
                  alt="平均年収"
                  sx={{
                    width: [110, 110, 250],
                    height: [110, 110, 250],
                  }}
                />
                <div
                  sx={{
                    position: 'absolute',
                    top: '47%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: ['0.5rem', '0.5rem', '1rem'],
                    fontWeight: 'bolder',
                  }}
                >
                  リモート案件
                  <br />
                  <strong>80%</strong>以上
                </div>
              </li>
              <li
                sx={{
                  position: 'relative',
                  width: [110, 110, 250],
                  height: [110, 110, 250],
                  display: ['none', 'none', 'none', 'block'],
                }}
              >
                <img
                  src="star.png"
                  alt="平均年収"
                  sx={{
                    width: [110, 110, 250],
                    height: [110, 110, 250],
                  }}
                />
                <div
                  sx={{
                    position: 'absolute',
                    top: '47%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: ['0.4rem', '0.4rem', '1rem'],
                    fontWeight: 'bolder',
                  }}
                >
                  業界最大級の
                  <br />
                  案件
                  <br />約<strong>4000</strong>件以上
                </div>
              </li>
            </ul>
            <p
              sx={{
                display: 'flex',
                flexDirection: ['column', 'column', 'column', 'row'],
                gap: ['0.2rem', '0.2rem', '0.2rem', '2rem'],
                fontSize: '0.5rem',
                textAlign: 'left',
                margin: ['0', '0', '0', '0.8rem 0 1.2rem'],
                color: '#fff',
                fontWeight: 400,
              }}
            >
              <div sx={{ marginLeft: ['1rem', '1rem', '1rem', '4rem'] }}>
                ※1 2024年01月時点の週5稼働者支払報酬額(税込)の平均額
              </div>
              <div sx={{ marginLeft: ['1rem', '1rem', '1rem', '0'] }}>
                ※2 2024年01月時点の総案件におけるリモート割合
              </div>
              <div
                sx={{
                  display: ['none', 'none', 'none', 'block'],
                  marginLeft: ['1rem', '1rem', '1rem', '0'],
                }}
              >
                ※3 2024年01月時点の総案件数
              </div>
            </p>
          </div>
        </main>
      </div>
    </Fragment>
  )
}

export default Main
