/** @jsx jsx */
import { jsx } from 'theme-ui'
import Layout from '../components/layout'
import { Box } from 'theme-ui'
import { useColorMode } from 'theme-ui'
import Main from '../components/Home/Main'
import Search from '../components/Home/Search'
import PopularProjects from '../components/Home/PopularProjects'
import Foot from '../components/Home/Foot'
import Register from '../components/Home/Register'
import RegisterVoice from '../components/Home/RegisterVoice'
import { FooterList } from '../components/FooterList'
import Footer from '../components/footer'

const Cara = () => {
  const [_, setColorMode] = useColorMode()
  setColorMode('light')

  return (
    <Layout>
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          overflow: 'hidden scroll',
          transform: 'translate3d(0px, 0px, 0px)',
          fontFamily: 'Noto Sans JP, sans-serif',
        }}
      >
        <Box
          sx={{
            overflow: 'hidden',
            position: 'absolute',
            width: '100%',
            transform: 'translate3d(0px, 0px, 0px)',
          }}
        >
          <Main />
          <Search />
          <Foot />
          <PopularProjects />
          <RegisterVoice />
          <Register />
          <FooterList />
          <Footer />
        </Box>
      </Box>
    </Layout>
  )
}

export default Cara
