/** @jsx jsx */
import { jsx } from 'theme-ui'

const Foot = () => {
  const handleButtonClick = () => {
    open(
      'https://timerex.net/s/info_f08c_3b71/2a13158e',
      '_blank',
      'noopener,noreferrer',
    )
  }
  return (
    <div
      sx={{
        backgroundColor: 'rgba(108, 155, 208, 0.14)',
        objectFit: 'cover',
        width: '100%',
        paddingTop: '40px',
        paddingBottom: '40px',
        color: 'black',
      }}
    >
      <div sx={{ textAlign: 'center' }}>
        <h3>プロジェクト探しで</h3>
        <div sx={{ fontWeight: 600, fontSize: ['1.5em', '2em'] }}>
          こんな<span sx={{ color: '#f28900' }}>悩み</span>ありませんか?
        </div>
        <div
          sx={{
            display: 'flex',
            flexDirection: ['column', 'column', 'row'],
            justifyContent: 'center',
            alignItems: 'center',
            gap: '10px',
            marginTop: '100px',
            fontSize: '12px',
            fontWeight: '700',
          }}
        >
          <div
            sx={{
              width: '250px',
              height: '100%',
              border: '1px solid #22426f',
              background: 'white',
              padding: [
                '3rem 2.2rem 0',
                '3rem 2.2rem 0',
                '3rem 0.6rem 0',
                '3rem 1.2rem 0',
              ],
            }}
          >
            <div
              sx={{
                border: '1px solid black',
                background: '#133059;',
                position: 'relative',
                bottom: '60px',
                left: '45px',
                width: '80px',
                color: 'white',
                transform: 'skew(-10deg)',
              }}
            >
              悩み１
            </div>
            期待したほど
            <div sx={{ color: '#f28900' }}>単価が上がらない</div>
            <img
              src="image0.png"
              sx={{
                paddingTop: '20px',
                width: '75px',
                height: '75px',
                objectFit: 'cover',
                objectPosition: 'top',
              }}
            />
          </div>
          <div
            sx={{
              width: '250px',
              height: '100%',
              border: '1px solid #22426f',
              background: 'white',
              padding: [
                '3rem 2.2rem 0',
                '3rem 2.2rem 0',
                '3rem 0.6rem 0',
                '3rem 1.2rem 0',
              ],
            }}
          >
            <div
              sx={{
                border: '1px solid black',
                background: '#133059;',
                position: 'relative',
                bottom: '60px',
                left: '45px',
                width: '80px',
                color: 'white',
                transform: 'skew(-10deg)',
              }}
            >
              悩み2
            </div>
            自由な働き方を求めているが
            <div sx={{ color: '#f28900' }}>実現できない</div>
            <img
              src="image (1).jpg"
              sx={{
                width: '75px',
                height: '55px',
                marginRight: '10px',
                marginTop: '20px',
                objectFit: 'cover',
                objectPosition: 'top',
              }}
            />
          </div>
          <div
            sx={{
              width: '250px',
              height: '100%',
              border: '1px solid #22426f',
              background: 'white',
              padding: [
                '3rem 1.8rem 0',
                '3rem 1.8rem 0',
                '3rem 0.2rem 0',
                '3rem 1.2rem 0',
                '3rem 1.2rem 0',
              ],
            }}
          >
            <div
              sx={{
                border: '1px solid black',
                background: '#133059;',
                position: 'relative',
                bottom: '60px',
                left: '47px',
                width: '80px',
                color: 'white',
                transform: 'skew(-10deg)',
              }}
            >
              悩み3
            </div>
            <span sx={{ color: '#f28900' }}>自身のキャリアをどのように</span>
            <br />
            描いていけば良いのか悩んでいる
            <img
              src="image (2).jpg"
              sx={{
                width: '75px',
                height: '55px',
                marginRight: '10px',
                marginTop: '20px',
                objectFit: 'cover',
              }}
            />
          </div>
          <div
            sx={{
              width: '250px',
              height: '100%',
              border: '1px solid #22426f',
              background: 'white',
              padding: [
                '3rem 2.2rem 0',
                '3rem 2.2rem 0',
                '3rem 0.6rem 0',
                '3rem 1.2rem 0',
              ],
            }}
          >
            <div
              sx={{
                border: '1px solid black',
                background: '#133059;',
                position: 'relative',
                bottom: '60px',
                left: '45px',
                width: '80px',
                color: 'white',
                transform: 'skew(-10deg)',
              }}
            >
              悩み4
            </div>
            初めてのフリーランスで
            <br />
            <span sx={{ color: '#f28900' }}>何から始めればいいのか不安</span>
            <img
              src="image (3).png"
              sx={{
                width: '75px',
                height: '55px',
                marginRight: '10px',
                marginTop: '20px',
                objectFit: 'cover',
                objectPosition: 'top',
              }}
            />
          </div>
        </div>
        <div
          sx={{
            margin: [0, 0, '29px', '90px', 0],
            marginY: ['40px', '40px', '40px', '50px', '40px'],
            padding: ['20px', '20px', '15px'],
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: ['1.2rem', '1.2rem', '2rem'],
            fontWeight: 600,
          }}
        >
          <span>その悩み</span>
          <span
            sx={{
              position: 'relative',
              zIndex: '1',
              lineHeight: '1',
              '::after': {
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                borderBottom: '12px solid #f28900',
                zIndex: '-1',
              },
            }}
          >
            エンジビズ
          </span>
          <span>
            が
            <span
              sx={{
                color: '#f04b4c',
              }}
            >
              解決
            </span>
            します
          </span>
        </div>
        <button
          onClick={handleButtonClick}
          sx={{
            background: 'linear-gradient(90deg,#f2b100,#f28900)',
            border: '1px solid #f3af01',
            borderRadius: '5px',
            width: '320px',
            color: 'white',
            fontSize: '20px',
            fontWeight: '600',
            cursor: 'pointer',
            padding: '10px',
            margin: '10px',
            marginBottom: '40px',
          }}
        >
          コンサルタントに相談してみる
        </button>
      </div>
    </div>
  )
}

export default Foot
