/** @jsx jsx */
import { jsx } from 'theme-ui'

const Resigter = () => {
  const handleButtonClick = () => {
    open(
      'https://timerex.net/s/info_f08c_3b71/2a13158e',
      '_blank',
      'noopener,noreferrer',
    )
  }
  return (
    <div>
      <div
        sx={{
          backgroundColor: 'rgba(108, 155, 208, 0.14)',
          objectFit: 'cover',
          width: '100%',
          paddingBottom: '100px',
          color: 'black',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <div
          sx={{
            maxWidth: '960px',
            display: 'flex',
            flexDirection: 'column',
            gap: '2rem',
            marginX: '16px',
          }}
        >
          <div
            sx={{
              fontSize: '2.25rem',
              lineHeight: '2.5rem',
              fontWeight: 'bold',
              marginTop: '4rem',
              marginBottom: '2rem',
              textAlign: 'center',
            }}
          >
            利用者の声
          </div>
          <div
            sx={{
              display: 'flex',
              flexDirection: ['column', 'column', 'column', 'column', 'row'],
              alignItems: ['center', 'center', 'center', 'center', 'initial'],
              gap: '2rem',
            }}
          >
            <img
              src="people.webp"
              width={316}
              height={238}
              sx={{
                objectFit: 'cover',
              }}
            />
            <div
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
              }}
            >
              <div
                sx={{
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                }}
              >
                会社から学んだ新しい技術と成長
              </div>
              <div
                sx={{
                  fontSize: '0.8rem',
                  color: '#666',
                }}
              >
                K.W（男性32歳）サーバーサイドエンジニア 経験5年
              </div>
              <div
                sx={{
                  fontSize: '0.9rem',
                }}
              >
                エンジビズが提供する豊富なリソースと手厚いサポートのおかげで、独立に向けた不安や懸念を解消し、自信を持ってフリーランスエンジニアとしての道を歩む決意を固めることができました。
              </div>
              <div
                sx={{
                  fontSize: '1.5rem',
                  color: '#f04b4c',
                }}
              >
                報酬: 月額75万円
              </div>
            </div>
          </div>
          <div
            sx={{
              display: 'flex',
              flexDirection: ['column', 'column', 'column', 'column', 'row'],
              alignItems: ['center', 'center', 'center', 'center', 'initial'],
              gap: '2rem',
            }}
          >
            <img
              src="people1.webp"
              width={316}
              height={238}
              sx={{
                objectFit: 'cover',
              }}
            />
            <div
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
              }}
            >
              <div
                sx={{
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                }}
              >
                自身の市場価値を知り、フリーランスとしての道を勧められた
              </div>
              <div
                sx={{
                  fontSize: '0.8rem',
                  color: '#666',
                }}
              >
                C.C（女性25歳）フロントエンドエンジニア 経験3年
              </div>
              <div
                sx={{
                  fontSize: '0.9rem',
                }}
              >
                以前はSES企業でシステムエンジニアとして働いていましたが、仕事のストレスと将来への不安がありました。そんな時、友人の紹介で参加したエンジビズテックサミットで、
                自分のスキルと市場価値について詳しく知ることができました。
              </div>
              <div
                sx={{
                  fontSize: '1.5rem',
                  color: '#f04b4c',
                }}
              >
                報酬: 月額70万円
              </div>
            </div>
          </div>
          <div
            sx={{
              display: 'flex',
              flexDirection: ['column', 'column', 'column', 'column', 'row'],
              alignItems: ['center', 'center', 'center', 'center', 'initial'],
              gap: '2rem',
            }}
          >
            <img
              src="people2.webp"
              width={316}
              height={238}
              sx={{
                objectFit: 'cover',
              }}
            />
            <div
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
              }}
            >
              <div
                sx={{
                  fontSize: '1.2rem',
                  fontWeight: 'bold',
                }}
              >
                不安を抱えた私がエンジニアとして独立を決意した理由
              </div>
              <div
                sx={{
                  fontSize: '0.8rem',
                  color: '#666',
                }}
              >
                M.N（男性35歳）サーバーサイドエンジニア 経験10年
              </div>
              <div
                sx={{
                  fontSize: '0.9rem',
                }}
              >
                エンジビズテックサミットで私は将来のキャリアパスに対する不安から独立を決意しました。自分のスキルと経験を最大限に活かし、自由にプロジェクトを選びたいと思ったからです。
              </div>
              <div
                sx={{
                  fontSize: '1.5rem',
                  color: '#f04b4c',
                }}
              >
                報酬: 月額85万円
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Resigter
