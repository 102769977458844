/** @jsx jsx */
import { jsx } from 'theme-ui'

const Resigter = () => {
  const handleButtonClick = () => {
    open(
      'https://timerex.net/s/info_f08c_3b71/2a13158e',
      '_blank',
      'noopener,noreferrer',
    )
  }
  return (
    <div>
      <div
        sx={{
          objectFit: 'cover',
          width: '100%',
          paddingTop: '70px',
          paddingBottom: '100px',
          color: 'black',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img
          src="/glo.png"
          alt="glo.png"
          sx={{
            width: '160px',
            maxWidth: '250px',
          }}
        />
        <div
          sx={{
            textAlign: 'center',
            fontSize: ['1.2rem', '1.5rem', '2rem'],
            fontWeight: 600,
          }}
        >
          <p>
            <span>４人に</span>
            １人
            <span>が</span>
            <span>選ぶ</span>
            <span>フリーランス！</span>
            <br />
            あなたもその一員に！
          </p>
        </div>
        <button
          onClick={handleButtonClick}
          sx={{
            border: '1px solid #f3af01',
            background: 'linear-gradient(90deg,#f2b100,#f28900)',
            color: '#fff',
            cursor: 'pointer',
            borderRadius: '50px',
            width: '320px',
            padding: ['0.5rem 0', '1rem 0', '1rem 0'],
            textAlign: 'center',
            boxShadow: '0 5px 10px 0',
            fontWeight: 500,
            fontSize: '1.5rem',
          }}
        >
          相談する
        </button>
      </div>
    </div>
  )
}

export default Resigter
